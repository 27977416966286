// IMPORTS
@import (once) "/node_modules/swiper/swiper.less";
@import (once) "/node_modules/swiper/modules/navigation.less";
@import (once) "/node_modules/swiper/modules/pagination.less";
@import (once) "/node_modules/swiper/modules/autoplay.less";

@import (once) "settings.less";

// GENERAL
body {
    overflow-x: hidden;
    background-color: white;
}

.goshop-modal-content {
    display: none;
}

main {
    transition: padding 0.2s ease-in-out;
}

#container {
    min-height: 100vh;
    min-height: -webkit-fill-available;
    overflow: clip;

    // IMPORTANT
    display: flex;
    flex-direction: column;

    .inner-wrapper > *:not(.inner) {
        width: 100%;
    }

    #footer {
        margin-top: auto;
    }
}

// SHOP-HEADER
.header-sentinel {
    /*position: absolute;*/
    top: 0;
    left: 0;
}

.privacy-sentinel {
    height: 0;
    clear: both;
}

@media (min-width: 1280px) {
    .shop-header {
        position: sticky;
        top: 0;
        left: 0;
        background-color: white;
        width: 100%;
        transition: box-shadow .2s ease-in-out, transform .3s ease-in-out;
        z-index: 1000;

        &.stuck {
            .shadow();
            transform: translateY(-30px);

            .header-bar {
                transform: translateY(30px);
            }

            .logo {
                transform: translateY(20px) scale(.8);
            }

            #search-engine,
            #cart-box, #wishlist-box, .log-button {
                transform: translateY(14px);
            }

            #main-bar {
                height: 40px;

                a {
                    padding: 8px 0;
                }
            }
        }
    }

    body:has(.shop-header.stuck) {

        main {
            padding-top: 16px;
        }

        .top-bar > * {
            opacity: 0;
        }
    }
}

// TOP BAR
body[data-type="cart"] {

    .top-bar {
        display: none;
    }

    main {
        padding-top: 0px !important;
    }
}

.top-bar {
    position: relative;
    display: flex;
    gap: 24px;
    justify-content: space-between;
    align-items: center;
    color: white;
    font-weight: 700;
    font-size: 14px;
    padding-block: 12px;

    > * {
        transition: opacity 0.2s ease;
    }

    &:empty {
        display: none;
    }

    &:before {
        .full-bg();
        background-color: @sec-color;
    }

    .top-bar-texts {
        display: flex;
        gap: 40px;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        &:has(> *:nth-child(2)) {
            justify-content: space-between;
        }

        > * {
            display: flex;
            align-items: center;
            gap: 8px;
            overflow: hidden;
        }

        span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .wall:before {
            .before();
            content: "\e915";
        }

        .poland {
            height: 24px;

            img {
                margin-top: -2px;
            }
        }
    }

    &:has(> *:only-child) {
        height: fit-content;

        .top-bar-texts { //as only child
            overflow: initial;
            flex: 1;

            > * {
                overflow: initial;

                &:only-child {
                    width: 100%;
                    justify-content: center;
                    height: initial;
                }
            }

            .poland {
            }

            span {
                white-space: initial;
                overflow: initial;
                text-overflow: initial;
            }
        }

        .top-bar-producers { //as only child
            height: fit-content;
            justify-content: center;
            flex: 1;
            width: none;
            overflow: visible;

            .producers-container {
                width: initial;
                overflow: visible;

                &:before {
                    display: none;
                }
            }

            .scrolling-producers {
                flex-wrap: wrap;
                animation: none !important;
                position: relative;
                transform: none;
            }
        }
    }
}

.top-bar-producers {
    display: flex;
    align-items: center;
    height: 24px;

    > span {
        white-space: nowrap;
    }
}

.producers-container {
    position: relative;
    overflow: hidden;
    display: flex;
    white-space: nowrap;
    height: 100%;
    overflow: hidden;

    &:has(.producer:nth-child(5)) {
        width: 430px;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            background: linear-gradient(90.00deg, rgb(46, 49, 51) 36.111%,rgba(46, 49, 51, 0) 100%);
            height: 100%;
            width: 36px;
            z-index: 1;
        }
    }

    a {
        color: white;
        margin-left: 4px;

        &:after {
            content: ',';
            margin-left: -3px;
        }

        &:only-child,
        &:last-child {

            &:after {
                display: none;
            }
        }
    }
}

.scrolling-producers {
    display: flex;
    align-items: center;
    background: @sec-color;
    left: 0;
    top: 1px;

    &:has(.producer:nth-child(5)) {
        animation: showText 25s infinite linear;
        transform: translateX(16px);
        position: absolute;
    }
}

@keyframes showText {

    2% {
        transform: translateX(16px);
    }
    48% {
        transform: translateX(calc(-100% + 430px));
    }
    52% {
        transform: translateX(calc(-100% + 430px));
    }
    98% {
        transform: translateX(16px);
    }
}

@media(max-width: 1680px) {

    .producers-container:has(.producer:nth-child(5)) {
        width: 300px;
    }

    @keyframes showText {

        2% {
            transform: translateX(16px);
        }
        48% {
            transform: translateX(calc(-100% + 300px));
        }
        52% {
            transform: translateX(calc(-100% + 300px));
        }
        98% {
            transform: translateX(16px);
        }
    }
}

// HEADER BAR
.header-bar {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    padding-block: 12px 14px;
    height: 48px;
    color: @header-color;
    transition: transform 0.3s ease-in-out;
    z-index: 102;
    white-space: nowrap;

    &:before {
        content: "";
        background-color: @border-color;
        position: absolute;
        width: 101vw;
        height: 1px;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: -1;
    }

    &:empty {
        display: none;
    }
}

.header-bar-left,
.header-bar-right {
    flex: 1;

    &:only-child {
        width: 100%;
    }
}

.header-bar-left {
    display: flex;
    gap: 20px;
    justify-content: flex-start;
    align-items: center;

    &:only-child {
        justify-content: center;
    }

    > span {
        font-weight: 700;
    }

    > a {
        float: left;
        clear: both;
        color: @header-color;
        padding-left: 28px;
        position: relative;

        &:before {
            font-family: icomoon;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            font-size: 20px;
        }

        &:hover {
            text-decoration: underline;
        }

        &:first-of-type {
            margin-left: -9px;
        }
    }

    .phone:before {
        content: "\e913";
    }

    .email:before {
        content: "\e912";
    }
}

.header-bar-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 40px;

    &:empty {
        display: none;
    }

    &:only-child {
        justify-content: space-between;
    }
}

.header-location-trigger {
    font-weight: 700;
    display: flex;
    align-items: center;
    cursor: pointer;
    text-align: center;
    position: relative;
    max-width: 500px;

    &:before {
        .before();
        content: "\e911";
        font-size: 20px;
        margin-right: 8px;
    }

    &:after {
        .before();
        content: "\e91a";
        font-size: 16px;
        margin-left: 6px;
    }

    .header-location-open {
        position: absolute;
        transform: translate(0, -10px);
        background: @sec-color;
        color: @footer-gray;
        cursor: initial;
        gap: 4px;
        filter: drop-shadow(0 3px 8px rgba(0, 0, 0, 0.2));
        opacity: 0;
        visibility: hidden;
        transition: all 0.2s ease;
        border-radius: var(--borderRadius);
        top: calc(100% + 10px);
        right: 0;
        display: flex;
        flex-direction: column;
        font-size: 16px;
        padding: 4px;
        max-width: 488px;
        white-space: initial;

        .location-text {
            pointer-events: none;
            padding: 12px;
            display: flex;
            flex-direction: column;

            > span {
                font-weight: initial;
                line-height: 1.2;
                font-size: 14px;
            }
        }

        .map-wrapper {
            width: 480px;
            aspect-ratio: ~"480/320";

            iframe {
                .img-contain();
                display: none;
                border-radius: 4px;
            }
        }
    }

    &.open {

        &:after {
            transform: rotate(180deg);
        }

        .header-location-open {
            opacity: 1;
            visibility: visible;
            transform: translate(0, 0);
            display: flex;

            iframe {
                display: block;
            }
        }
    }
}

.header-bar-right:only-child .header-location-trigger .header-location-open {
    right: initial;
    left: 0;
}

.header-social-links {
    display: flex;
    gap: 6px;
    align-items: center;

    li {
        position: relative;
        width: var(--iconSize);
        aspect-ratio: 1;

        img {
            .img-contain();
        }

        a {
            position: absolute;
            inset: 0;
            z-index: 1;
        }
    }
}

//SHOP HEADER
.shop-header-container {
    display: flex;
    align-items: center;
    height: 128px;
    justify-content: space-between;
}

// LOGO
.logo {
    display: flex;
    align-items: stretch;
    transform-origin: left top;
    transition: transform .3s ease-in-out;

    img {
        display: block;
        height: 80px;
        width: 346px;
    }
}

// HEADER ICONS
.header-tools-wrapper {
    display: flex;
    gap: 32px;
}

@media (min-width: 1280px) {
    .tree-trigger {
        display: none;
    }

    .tree-canvas {
        display: none;
    }

    #cart-box,
    #wishlist-box,
    .log-button {
        position: relative;
        transition: transform .3s ease-in-out;
        padding-left: 44px;

        &:before {
            font-family: icomoon;
            position: absolute;
            left: 0;
            top: 0;
            font-size: 32px;
            line-height: 32px;
            height: 32px;
            color: @header-color;
        }

        em {
            display: none;
        }

        span,
        b {
            display: block;
            font-size: 14px;
            text-transform: uppercase;
            line-height: 1.2;
            color: @font-color;
        }

        &:hover {

            span,
            b {
                color: @header-color;
            }
        }
    }

    .log-button {

        &:before {
            content: "\e90f";
        }
    }

    #cart-box {

        &:before {
            content: "\e91b";
        }
    }

    #wishlist-box {

        &:before {
            content: "\e90e";
        }

        &[data-wishlist-count="0"]:after {
            display: none;
        }
    }

    #cart-box i,
    #wishlist-box:after {
        content: attr(data-wishlist-count);
        position: absolute;
        font-style: normal;
        top: -1px;
        left: 23px;
        background: @main-color;
        width: 16px;
        height: 16px;
        border-radius: 50px;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-size: 10px;
    }
}

// SEARCH ENGINE
.search-engine-trigger,
.search-engine-close {
    display: none;
}

@media (min-width: 1280px) {
    #search-engine {
        position: relative;
        width: 396px;
        float: right;
        z-index: 101;
        transition: transform .3s ease-in-out;
        left: 58px;

        &.show {

            #suggestions {
                opacity: 1;
                visibility: visible;
                transform: translateY(0);
                transition: all .2s ease-in-out;

                .suggestion-last-phrases {
                    display: none;
                }
            }
        }

        &.show-with-last-phrases {

            &.no-products .no-products-info,
            a.suggestions-advanced-search {
                display: none;
            }

            #suggestions {
                opacity: 1;
                visibility: visible;
                transform: translateY(0);
                transition: all .2s ease-in-out;

                .suggestion-result-categories,
                .suggestion-result-producers,
                .suggestion-result-products {
                    display: none;
                }
            }

            a.suggestions-advanced-search {
                display: block;
            }
        }

        &.no-products {

            .no-products-info {
                display: block;

                & + .suggestion-column-wrapper .suggestion-left-column:not(.hidden) {
                    margin-top: 10px;
                }
            }
        }

        input[type="text"] {
            width: 100%;
            height: 48px;
            padding: 0 64px 0 22px;
            background-color: @light-bg;
            border: none;
            border-radius: 30px;
            color: @header-color;
            font-size: 16px;
            font-family: @main-font-family;

            &::-webkit-input-placeholder {
                color: @font-color;
            }
            &::-moz-placeholder {
                color: @font-color;
            }
            &:-ms-input-placeholder {
                color: @font-color;
            }
            &:-moz-placeholder {
                color: @font-color;
            }
        }

        button {
            position: absolute;
            top: 0;
            right: 0;
            width: 60px;
            height: 48px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: @header-color;

            &:after {
                .before();
                content: "\e910";
            }

            &:hover {
                color: @font-color;
            }
        }
    }
}

// SUGGESTIONS
#suggestions {
    position: absolute;
    top: ~"calc(100% + 10px)";
    left: 0;
    background-color: white;
    width: 720px;
    padding: 22px;
    .shadow();
    opacity: 0;
    visibility: hidden;
    border-radius: 8px;
    display: flex;
    flex-wrap: wrap;
    transform: translateY(-10px);

    .no-products-info {
        display: none;
        font-size: 18px;
        font-weight: bold;
        color: @header-color;
        width: 100%;
    }

    &.loading {

        > *:not(.suggestions-loading) {
            display: none;
        }

        .suggestions-loading {
            display: flex;
        }
    }
}

.suggestions-loading {
    height: 200px;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: none;

    span {
        border-radius: 50%;
        border: 4px solid @border-color;
        border-top-color: @header-color;
        animation: spin .5s infinite linear;
        transform: translateZ(0) rotate(0deg);
        transform-origin: center;
        -webkit-transform-style: preserve-3d;
        width: 100px;
        height: 100px;
    }
}

.suggestion-column-wrapper {
    display: flex;
    width: 100%;
}

.suggestion-left-column {
    width: 33%;
    flex-shrink: 0;

    &.hidden {
        display: none;
    }
}

.suggestion-result-categories,
.suggestion-result-producers,
.suggestion-result-products,
.suggestion-last-phrases {

    &.hidden {
        display: none;
    }

    &:before {
        content: attr(data-label);
        display: block;
        color: @header-color;
        font-weight: bold;
        font-size: 18px;
    }
}

.suggestion-result-categories,
.suggestion-result-producers {
    padding-right: 30px;

    a {
        display: inline-block;
        padding: 2px 0;
        color: @font-color;

        &:hover {
            text-decoration: underline;
        }
    }
}

.suggestion-last-phrases {
    width: 100%;

    span {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 5px 0;

        &:before {
            content: '\e802';
            font-family: icomoon;
            color: @header-color;
            float: left;
            width: 35px;
            font-size: 18px;
            flex-shrink: 0;
        }

        &:hover {
            color: @header-color;
        }
    }

    li {
        display: flex;
        align-items: center;

        &.hidden {
            display: none;
        }
    }

    .delete-hint-suggestion {
        margin-left: auto;
        font-style: normal;
        color: @header-color;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 11px;
        cursor: pointer;

        &:after {
            content: '\e803';
            font-family: icomoon;
        }

        &:hover {
            color: @error-color;
        }
    }
}

.suggestion-result-categories {
    margin-bottom: 22px;
}

.suggestion-result-products {
    flex-grow: 1;
}

.suggestion-product {
    margin-top: 10px;

    &:hover {
        
        b {
            text-decoration: underline;
        }
    }

    a {
        display: block;
        position: relative;
        min-height: 60px;
        padding-left: 80px;
    }

    .size-custom {
        position: absolute;
        top: 0;
        left: 0;
        width: 60px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    img {
        max-width: 100%;
        max-height: 100%;
        align-self: center;
    }

    b {
        color: @header-color;
        display: block;

    }

    span {
        color: @font-color;
    }
}

a.suggestions-advanced-search {
    width: 100%;
    margin-top: 12px;
    color: @header-color;

    &:hover {
        text-decoration: underline;
    }
}

// MAIN BAR
@media (min-width: 1280px) {
    #main-bar {
        position: relative;
        display: flex;
        justify-content: space-between;
        height: 56px;
        gap: 16px;
        transition: height 0.2s ease-in-out;

        a {
            color: white;
            font-weight: 700;
            display: block;
            white-space: nowrap;
            padding: 16px 0;
            text-transform: uppercase;
            transition: padding 0.2s ease-in-out;

            &:hover {
                color: #9C9D9D;
            }
        }

        &:after {
            content: "";
            background-color: @sec-color;
            position: absolute;
            width: 101vw;
            height: 100%;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            z-index: -1;
        }
    }

    .mainmenu {
        display: contents;
    }

    #tree,
    .tree-top {
        display: contents;

        .cms-rwd {
            display: none;
        }
    }

    .tree-top > ul {
        position: relative;
        display: contents;

        small {
            display: none;
        }

        ul { //both
            position: absolute;
            background-color: @sec-color;
            opacity: 0;
            visibility: hidden;
            transform: translateY(-10px);
            transition: all .1s ease-in-out;
            min-width: 226px;
            padding-block: 14px 12px;

            a {
                padding: 8px 20px !important;
            }
        }

        .t0 { //in main bar
            position: relative;
            z-index: 100;

            &:hover > ul {
                opacity: 1;
                visibility: visible;
                transform: translateX(0);
                transition-delay: 0.2s;
            }

            > ul {
                top: calc(100% - 2px);
                left: -20px;
            }
        }

        .parent.t0 > a {
            display: flex !important;
            gap: 6px;
            align-items: center;

            &:after {
                content: "\e91a";
                font-family: icomoon;
                font-weight: normal;
                font-size: 16px;
                line-height: 24px;
            }
        }

        .parent.t0:hover {

            > a:after {
                transform: scaleY(-1);
            }
        }

        li:not(.t0) { //nested
            position: relative;

            &:hover {

                > ul {
                    opacity: 1;
                    visibility: visible;
                    transform: translateX(0);
                    transition-delay: 0.2s;
                }
            }

            ul {
                top: -12px;
                left: calc(100% + 1px);
                padding: 12px 0;
                transform: translateX(-10px);

                &:before { // redundancy for hover
                    content: '';
                    height: 100%;
                    width: 15px;
                    background: transparent;
                    display: block;
                    right: calc(100% - 5px);
                    top: 0;
                    position: absolute;
                }
            }
        }

        .parent:not(.t0) > a {
            display: flex !important;
            align-items: center;
            justify-content: space-between;
            gap: 6px;

            &:after {
                content: "\e91a";
                font-family: icomoon;
                font-weight: normal;
                font-size: 16px;
                line-height: 24px;
                transform: rotate(-90deg);
            }
        }

        li:hover {
            > a {
                color: #9C9D9D !important;
            }
        }
    }
}

// PRODUCTS
@keyframes productImageOpacity {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.products-list {
    .grid();
    grid-template-columns: repeat(var(--prodCols), minmax(0, 1fr));
    grid-column-gap: var(--prodGap);
    grid-row-gap: var(--prodGapVertical);
    width: 100%;
}

.product {
    position: relative;
    .grid();
    grid-template-rows: auto 1fr;

    &.swiper-slide {
        box-sizing: border-box;

        .product-name {
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            display: -webkit-box !important;
            height: ~"calc(2 * (var(--fontSize) * var(--lineHeight)))";
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    &:after {
        position: absolute;
        right: calc(-0.5 * var(--prodGap));
        top: 0;
        content: '';
        height: 100%;
        width: 1px;
        background: @border-color;
    }

    &:last-child,
    &:nth-child(4n) {

        &:after {
            display: none;
        }
    }
}

.product-image {
    aspect-ratio: 1/1;
    max-width: 100%;
    position: relative;
    transition: transform .2s ease-in-out;
    margin-bottom: 18px;

    img {

        &:nth-of-type(2) {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-width: 100%;
            max-height: 100%;
            opacity: 0;
        }
    }
}

a.product-name {
    font-weight: 700;
    font-size: 14px;
    display: block;
    text-align: center;
    text-transform: uppercase;
    color: @header-color;
    transition: opacity .1s ease-in-out;
    z-index: 1; // IMPORTANT
    margin-bottom: 10px;

    &:after {
        content: '';
        position: absolute;
        inset: 0;
    }

    &:first-letter {
        text-transform: uppercase;
    }
}

.product-bottom-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 6px;
    z-index: 1;
    min-height: 44px;
}

.product-price {
    height: 44px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;

    b, del {

        &:after {
            content: ' zł';
        }
    }

    b {
        font-size: 18px;
        font-weight: 700;
        color: @header-color;
    }

    del {
        font-size: 16px;
        color: #9C9D9D;
        position: relative;
        top: 2px;
    }
}

.product-add,
.product-wishlist {
    width: 44px;
    height: 44px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;

    &:before {
        .before();
    }
}

.product-add {
    background-color: @main-color;
    color: white;

    &:before {
        content: "\e91b";
    }

    &.disabled {
        display: none;
    }

    &:hover {
        background-color: @sec-color;
    }
}

.product-wishlist {
    margin-left: auto;
    background-color: @light-bg;
    color: @font-color;

    &:before {
        content: "\e90e";
    }

    &:hover {
        color: @main-color;
    }

    &.existing {
        background-color: @main-color;
        color: white;
    }
}

.product-badges {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 12px;
    font-weight: 700;
    color: white;
    display: flex;
    flex-direction: column;
    gap: 2px;
    justify-content: space-between;
    align-items: flex-end;
    text-align: center;

    span {
        background-color: #4F4F4F;
        padding: 2px 8px;

        &.promo-badge {
            background-color: @error-color;
            
            &:empty {
                display: none;
            }
        }
    }
}

.product-hover {
    padding: 16px;
    position: absolute;
    width: 100%;
    bottom: 0;
    opacity: 0;
    transform: translateY(8px);
    transition: all .1s ease-in-out;

    &:before {
        content: '';
        z-index: -1;
        position: absolute;
        top: 0;
        left: -2px;
        width: calc(100% + 4px);
        height: calc(100% + 2px);
        background-color: rgba(255, 255, 255, 0.95);
    }

    p {
        font-size: 14px;
        letter-spacing: 0.02em;
        color: @font-color;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        display: block;
        display: -webkit-box !important;
        max-height: ~"calc(4 * (var(--fontSize) * var(--lineHeight)))";
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

@media (min-width: 1280px) {
    .product:hover {

        .product-hover {
            opacity: 1;
            transform: translateY(0);
        }

        .product-name {
            text-decoration: underline;
        }
    }

    .product.has-photo:hover .product-image img {

        &:first-of-type {
            opacity: 0;
        }

        &:nth-of-type(2) {
            display: block;
            animation: productImageOpacity .2s ease-in-out forwards;
        }
    }
}

// BREADCRUMB
#breadcrumb {
    padding: 24px 0;
    margin-bottom: ~"calc(var(--rowGap) * -1)";
    font-size: 14px;

    ul {
        display: flex;
        flex-wrap: wrap;
    }

    li {
        display: flex;

        &:after {
            content: '-';
            margin: 0 4px;
        }

        &:last-child {

            &:after {
                display: none;
            }
        }
    }

    a,
    span {
        color: @header-color;
        display: block;
    }
}

[data-type="mainpage"] #breadcrumb {
    display: none;
}

// LOADER
#loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255,255,255,.5);
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity .2s linear;
    pointer-events: none;

    &.show {
        opacity: 1;
        pointer-events: auto;
    }

    div {
        border-radius: 50%;
        border: 4px solid @border-color;
        border-top-color: @header-color;
        animation: spin .5s infinite linear;
        transform: translateZ(0) rotate(0deg);
        transform-origin: center;
        -webkit-transform-style: preserve-3d;
        width: 100px;
        height: 100px;
    }
}

@media (max-width: 600px) {
    .category-header-wrapper {
        display: contents;
    }

    .category-description {
        order: 2;
    }
}